<template>
    <v-sheet style="background: #f2f2f2;" class="text-center fill-height overflow-hidden pb-12">
        <v-container class="title" style="color:white; background-color:#E60E13!important;">
            <v-btn absolute to="/" dark icon left>
                <v-icon large> mdi-chevron-left</v-icon>
            </v-btn>
            Arma tu platillo
        </v-container>

        <div v-if="horario('noche')==true" class="pb-8" style="background-color:#f2f2f2!important;">
            <template v-for="producto in productos">
                <v-card flat v-bind:key="producto.id" style="background-color:#f2f2f2!important;">
                    <div @click="seleccionar(producto)" style="text-align:left;">
                        <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                            <v-col cols="4">
                                <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                            </v-col>
                            <v-col cols="7" class="py-5">
                                {{producto.nombre}}
                                <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                <br><a style="font-size:12px;"></a>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </template>
        </div>

        <!-- boton ver plato -->
        <v-card class="perro" :to="{ path: '/plato'}" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="3">
                    <a style="color:white; background:rgb(255 255 255 / 0.21); padding:5px 10px; border-radius:50%;">{{plato('cantidad')}}</a>
                </v-col>
                <v-col style="cursor:pointer;" cols="5">
                    Ver Plato
                </v-col>
                <v-col style="cursor:pointer;" cols="4">
                    {{plato('total').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>

        <!-- detalle producto -->
        <v-row justify="center" style="z-index: 999999999;">
            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card  class="mx-auto my-12">
                    <detalleTaco v-bind:seleccion="seleccion" @closeDialog="closeDialog"/>
                </v-card>
            </v-dialog>
        </v-row>
    </v-sheet>
</template>


<script>
import DetalleTaco from "../components/detalleTaco"
export default {
    props:{
            categoria:String
        },
    components: {
        'detalleTaco':DetalleTaco,
    }, 
    data () {
      return {
        dialog: false,
        carrito:[{
            cantidad:0,
            producto:'',
            total:0
        }],
        seleccion:'',
      }
    },
    methods:{
        horario(cuando){
            var fecha = new Date()
            var hora = fecha.getHours()+''+fecha.getMinutes()
            /* HORARIO DIA Y NOCHE */
            if(cuando=='noche'){
                /*
                if(hora>=1900 && hora<=1200){
                    return true
                }else{
                    return false
                }
                */
               return true
            }
            if(cuando=='dia'){
                /*
                if(hora>=900 && hora<=930){
                    return true
                }else{
                    return false
                }
                */
               return false
            }
            
        },
        closeDialog: function(params) {
            this.dialog = params;
            this.seleccion = ''
        },
        seleccionar(item){
            this.seleccion = item
            this.dialog=true
        },
        plato(que) {
            var perro = this.$store.state.plato.platos
            if(perro!=undefined){
                var sum = 0
                for(var i=0; i<perro.length; i++){
                    if(que=='cantidad'){
                        sum = sum + perro[i].cantidad
                    }else{
                        sum = sum + perro[i].total
                    }
                }
                return sum
            }else{
                return 0
            }
        },
    },
    computed:{
        productos(){
            return this.products.filter(producto=>producto.categoria == this.categoria)
        },
        sucursal(){
            return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.id == localStorage.getItem("sucursal"))[0]
        },
        products(){
            return this.$store.state.producto.productos.filter(producto=>producto.sucursales[localStorage.getItem("sucursal")-1].disponible == 1)
        },
    },
    created(){
        this.$store.dispatch('producto/getProductos') 
        this.$store.dispatch('sucursal/getSucursales')
    }
  }

</script>

<style>
.v-item-group.theme--light.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.primary--text{
    border-bottom:1px #e8e8e8 solid;
}
.theme--light.v-tabs-items {
    background-color: #f1f1f1!important;
}

.perro .row.text-center.px-4.py-3 {
    height: 70px;
}
</style>