<template>
    <div>
        <v-btn style="z-index: 9; background-color:rgb(255 255 255 / 21%);" class="mt-2" absolute right dark @click="close()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
        </template>
        <v-img height="250" :src="seleccion.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
        <v-card-title>{{seleccion.nombre}}</v-card-title>
        <v-card-text>
            <v-row align="center" class="mx-0">
                <v-rating :value="5" color="amber" dense half-increments readonly size="14"></v-rating>
                <div class="grey--text ml-4">
                5
                </div>
            </v-row>
            <div class="my-4 subtitle-1">
                {{seleccion.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </div>
            <div>{{seleccion.descripcion}}</div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>

        <div class="mx-4 pt-4" v-if="seleccion.extras!=undefined">
            <strong>Elije tus Extras</strong>
            <div style="height:30px;"></div>
            <v-checkbox style="margin-top:-20px;" v-for="(extra, index) in seleccion.extras"  :key="index"
                v-model="extras"
                :label="extra.extra + ' - ' + extra.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})"
                :value="extra.extra"
            ></v-checkbox>
        </div>
        
    
    <div style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
        <v-row class="text-center px-4 py-3" style="color:white;">
            <v-col cols="5">
                <a style="color:white; background:rgb(255 255 255 / 0.21); padding:5px 10px; border-radius:5px;">  <strong style="cursor:pointer;" class="px-3" @click="cantidad=cantidad-1">- </strong> {{cantidad}} <strong class="px-3" style="cursor:pointer;" @click="cantidad=cantidad+1"> + </strong> </a>
            </v-col>
            <v-col @click="addToCart()" style="cursor:pointer;" cols="7">
                Agregar {{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </v-col>
        </v-row>
    </div>
    </div>
</template>

<script>
//import axios from "axios";
    export default {
        props:{
            seleccion:Object
        },
        data:()=>({  
            comentario:'',
            cantidad:1,
            index:0,
            extras:[]
        }),
        computed:{
            total(){
                
                var total = this.seleccion.valor

                if(this.extras!=null&&this.extras!=undefined&&this.extras!=''){
                    var totalExtras = 0
                    console.log(this.extras)
                    for(var i=0; i<this.extras.length; i++){
                        totalExtras = totalExtras + this.seleccion.extras.filter(extras=>extras.extra == this.extras[i]).map(extra=>extra.valor)[0]
                    }
                    total = total + totalExtras
                }
                return this.cantidad * total
            },
        },
        methods:{
            close () {
                this.cantidad=1
                this.$nextTick(() => {
                    this.$emit("closeDialog", false);
                })
            },
            addToCart() {
                const orden={producto:'', cantidad:'', total:'', comentario:'', extras:''}
                orden.extras = this.extras
                orden.producto = this.seleccion.id
                orden.total = this.total
                orden.cantidad = this.cantidad
                orden.comentario = this.comentario

                this.$store.dispatch("plato/addItem", orden).then(response => {
                    this.index = this.index+1
                    this.close()
                    this.extras=[]
                    this.comentario=''
                });
                
            },
        },
    }
</script>


