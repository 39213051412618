<template>
    <v-sheet style="background: linear-gradient(0deg, rgba(241,241,241,1) 65%, rgba(255,255,255,1) 65%);" class="text-center fill-height overflow-hidden">
        <v-container class="title" style="min-width:100vw!important;z-index:9999999;position:fixed; color:white;background-color:#E60E13!important;">
            <v-btn absolute to="/" dark icon left>
                <v-icon large> mdi-chevron-left</v-icon>
            </v-btn>
            <div><v-img style="margin-right:20px; max-width:120px!important; float:right;" src="http://tacoselguero.mx/wp-content/uploads/2021/03/logo.png"></v-img></div>
        </v-container>
        <div class="mt-12 pt-0" style="background: linear-gradient(0deg, white 40%, #E60E13 40%);">
            <div>
                <v-row class="pa-2">
                    <v-col cols="6" style="z-index: 99999999;">
                        <v-img style="height:100px!important;" :src="sucursal.foto"></v-img>
                    </v-col>
                    <v-col cols="6" class="pt-12 mt-0" style="font-size:12px;">
                        <a style="color:white;">Sucursal: <strong>{{sucursal.nombre}}</strong></a>
                        <br>
                        <br>
                        <v-btn icon dark elevation="0" x-small color="#011e7a" v-bind:href="'tel:'+sucursal.telefonos"><v-icon small> mdi-phone </v-icon></v-btn> Teléfono
                        <v-btn class="ml-2" icon dark elevation="0" x-small color="#011e7a" v-bind:href="'https://www.google.com.mx/maps/place/' + sucursal.direccion + '/@'" target="_blank"><v-icon small> mdi-map-marker </v-icon></v-btn> Dirección
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- Noche -->
        <v-tabs v-if="sucursal.status=='activo'  && horario('noche')==true" centered class="mb-12 pb-12">
            <v-tab>Tacos</v-tab>
            <v-tab-item>
                <v-card dark class="pa-4 mt-4 mx-4 elevation-0" color="#001e7a" @click="dialogTacos('Tacos')">
                    <strong>Orden de Tacos Personalizada</strong>
                    <br>
                    Selecciona la cantidad de tacos que quieras de nuestra variedad.
                    <br>
                    <v-btn class="mt-3" small color="#e60f14">Arma tu platillo! <v-icon> mdi-chevron-right</v-icon></v-btn>
                </v-card>
                <template v-for="producto in productos('Tacos')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>
            </v-tab-item>
            <v-tab>Más</v-tab>
            <v-tab-item>
                <template v-for="producto in productos('Mas')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>
            </v-tab-item>
            <v-tab>Antojitos</v-tab>
            <v-tab-item>
                <v-card dark class="pa-4 mt-4 mx-4 elevation-0" color="#001e7a" @click="dialogTacos('Antojitos')">
                    <strong>Orden de Antojitos Personalizada</strong>
                    <br>
                    Selecciona la cantidad de antojitos que quieras de nuestra variedad.
                    <br>
                    <v-btn class="mt-3" small color="#e60f14">Arma tu platillo! <v-icon> mdi-chevron-right</v-icon></v-btn>
                </v-card>
                <template v-for="producto in productos('Antojitos')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>
            </v-tab-item>
            <v-tab>Complementos</v-tab>
            <v-tab-item>
                <template v-for="producto in productos('Complemento')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>
            </v-tab-item>
            <v-tab>Bebidas</v-tab>
            <v-tab-item>
                <template v-for="producto in productos('bebidas')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>

            </v-tab-item>
        </v-tabs>
        <!-- Mañaneros -->
        <v-tabs v-if="sucursal.status=='activo' && (sucursal.nombre=='Revolucion' || sucursal.nombre=='Barragan') && horario('dia')==true" centered class="mb-12 pb-12">
            <v-tab>Tacos Mañaneros</v-tab>
            <v-tab-item>
                <v-card dark class="pa-4 mt-4 mx-4 elevation-0" color="#001e7a" @click="dialogTacos('Mananeros')">
                    <strong>Orden de Tacos Personalizada</strong>
                    <br>
                    Selecciona la cantidad de tacos que quieras de nuestra variedad.
                    <br>
                    <v-btn class="mt-3" small color="#e60f14">Arma tu platillo! <v-icon> mdi-chevron-right</v-icon></v-btn>
                </v-card>
                <template v-for="producto in productos('Mananeros')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>
            </v-tab-item>
            <v-tab>Bebidas</v-tab>
            <v-tab-item>
                <template v-for="producto in productos('bebidas')">
                    <v-card flat v-bind:key="producto.id">
                        <div @click="seleccionar(producto)" style="text-align:left; background-color:#f2f2f2!important;">
                            <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                                <v-col cols="4">
                                    <v-img style="height:70px!important;" :src="producto.foto"></v-img><!-- :src="'http://torstdio.site/files/' + producto.foto" -->
                                </v-col>
                                <v-col cols="7" class="py-5">
                                    {{producto.nombre}}
                                    <br><strong>{{producto.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                                    <br><a style="font-size:12px;"></a>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </template>
            </v-tab-item>
        </v-tabs>
        <!-- productos cerrado -->
        <div style="filter:opacity(0.21);" v-if="sucursal.status=='inactivo' || ((sucursal.nombre!='Revolucion' && sucursal.nombre!='Barragan') && horario('dia')==true) && currentUser!=2 || horario('dia')==false || horario('noche')==false">
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/close.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">La sucursal ya cerró</strong>
        </div>

        <!-- boton carrito noche -->
        <v-card v-if="sucursal.status=='activo' && horario('noche')==true" class="perro" :to="{ path: '/carrito'}" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="3">
                    <a style="color:white; background:rgb(255 255 255 / 0.21); padding:5px 10px; border-radius:50%;">{{cart('cantidad')}}</a>
                </v-col>
                <v-col style="cursor:pointer;" cols="5">
                    Ver carrito
                </v-col>
                <v-col style="cursor:pointer;" cols="4">
                    {{cart('total').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card><!-- boton carrito dia -->
        <v-card v-if="sucursal.status=='activo' && (sucursal.nombre=='Revolucion' || sucursal.nombre=='Barragan') && horario('dia')==true" class="perro" :to="{ path: '/carrito'}" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="3">
                    <a style="color:white; background:rgb(255 255 255 / 0.21); padding:5px 10px; border-radius:50%;">{{cart('cantidad')}}</a>
                </v-col>
                <v-col style="cursor:pointer;" cols="5">
                    Ver carrito
                </v-col>
                <v-col style="cursor:pointer;" cols="4">
                    {{cart('total').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>
        <!-- boton carrito cerrado-->
        <v-card  v-if="sucursal.status=='inactivo' || ((sucursal.nombre!='Revolucion' && sucursal.nombre!='Barragan') && horario('dia')==true)" class="perro" style="filter:opacity(0.21); z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="3">
                    <a style="color:white; background:rgb(255 255 255 / 0.21); padding:5px 10px; border-radius:50%;">{{cart('cantidad')}}</a>
                </v-col>
                <v-col style="cursor:pointer;" cols="5">
                    Ver carrito
                </v-col>
                <v-col style="cursor:pointer;" cols="4">
                    {{cart('total').toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>

        <!-- detalle producto -->
        <v-row justify="center" style="z-index: 999999999;">
            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card  class="mx-auto my-12">
                    <detalleProducto v-bind:seleccion="seleccion" @closeDialog="closeDialog"/>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- orden de tacos -->
        <v-row justify="center" style="z-index: 999999999;">
            <v-dialog v-model="dialogTaco" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card  class="mx-auto my-12">
                    <dialogTaco v-bind:categoria="categoria" @closeDialog="closeDialog"/>
                </v-card>
            </v-dialog>
        </v-row>

    </v-sheet>
</template>


<script>
import DetalleProducto from "../components/detalleProducto"
import dialogTaco from "../components/dialogTaco"
export default {
    components: {
        'detalleProducto':DetalleProducto,
        'dialogTaco':dialogTaco
    }, 
    data () {
      return {
        dialog: false,
        dialogTaco:false,
        carrito:[{
            cantidad:0,
            producto:'',
            total:0
        }],
        seleccion:'',
        categoria:'',
      }
    },
    methods:{
        horario(cuando){
            var fecha = new Date()
            var hora = fecha.getHours()+''+fecha.getMinutes()
            console.log(hora)
            /* HORARIO DIA Y NOCHE */
            if(cuando=='noche'){
                if(this.currentUser == 2){
                    return true
                }
                else if(hora>=1730 && hora<=100){
                    return true
                }else{
                    return false
                }
            }
            if(cuando=='dia'){
                if(this.currentUser == 2){
                    return true
                }
                else if(hora>=900 && hora<=930){
                    return true
                }else{
                    return false
                }
            }
            
        },
        dialogTacos(categoria){
            this.categoria = categoria
            this.dialogTaco=true;
        },
        closeDialog: function(params) {
            this.dialog = params;
            this.seleccion = ''
        },
        addToCart(id) {
            this.$store.dispatch("carrito/addItem", id);
        },
        productos(categoria){
            if(categoria!=undefined){
                return this.products.filter(producto=>producto.categoria == categoria)
            }else{
                return this.products.filter(producto=>producto.categoria != 'bebidas' && producto.categoria != 'complementos')
            }
        },
        seleccionar(item){
            this.seleccion = item
            this.dialog=true
        },
        cart(que) {
            var perro = this.$store.state.carrito.carritos
            if(perro!=undefined){
                var sum = 0
                for(var i=0; i<perro.length; i++){
                    if(que=='cantidad'){
                        sum = sum + perro[i].cantidad
                    }else{
                        sum = sum + perro[i].total
                    }
                }
                return sum
            }else{
                return 0
            }
        },
    },
    computed:{
        currentUser(){
            var user = this.$store.state.currentUser.user.id
            if(user!=undefined){
                return user
            }else{
                return 'perro'
            }
        },
        sucursal(){
            return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.id == this.$route.params.id)[0]
        },
        products(){
            return this.$store.state.producto.productos.filter(producto=>producto.sucursales[this.$route.params.id-1].disponible == 1)
        },
    },
    created(){
        this.$store.dispatch('producto/getProductos') 
        this.$store.dispatch('sucursal/getSucursales')
        localStorage.setItem("sucursal", this.$route.params.id);
    },
    mounted(){
      Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('sucursal/getSucursales')
          })
    },
  }

</script>

<style>
.v-item-group.theme--light.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.primary--text{
    border-bottom:1px #e8e8e8 solid;
}
.theme--light.v-tabs-items {
    background-color: #f1f1f1!important;
}

.perro .row.text-center.px-4.py-3 {
    height: 70px;
}
</style>